import Vue from "vue";
import Vuex from "vuex";
import { loader } from "@/store/modules/loader";
import { account } from "@/store/modules/account";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        alert,
        loader,
        account,
    },
});

export default store;
