<template>
  <v-container class="fill-height py-0">
    <v-row class="fill-height my-0" justify="space-around" style="overflow: hidden;">
      <v-card width="500">
        <v-img min-height="100%" position="right right" :src="require('@/assets/stars_blur.webp')">
          <div class="d-flex flex-column justify-space-around" style="margin-top: 250px">
            <v-btn
              class="ma-2"
              large
              color="secondary"
              to="/register"
            >
              Register
            </v-btn>
          </div>
        </v-img>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomePage',

  data: () => ({
  }),
}
</script>
