<template>
  <v-app>
    <v-main class="black">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>
<style>

.v-label {
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem;
  color: black !important;
}

.red_label .v-label{
  color: red !important;
}

.v-text-field--outlined fieldset {
  color: currentColor !important;
  border-width: 2px !important;
  border-radius: 5px !important;
  box-shadow: .5px 1px currentColor;
}

.v-btn__content{
  color: black !important;
}

.v-menu__content.theme--light.menuable__content__active{
  width: 25% !important;
}

</style>