import store from "@/store";
import Api from "@/services/api";
import {
    REGISTER, TOGGLE_LOADING,
} from "@/store/_actiontypes";
import {
  CLEAR_MESSAGE, SET_MESSAGE, SET_USER, SIGNUP_FAILURE, SIGNUP_SUCCESS,
} from "@/store/_mutationtypes";

const state = {
  user: null,
  message: null,
};

const actions = {
  [REGISTER](
    { commit },
    {
      userData
    }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        "learner",
          {
            login: userData.email,
            firstname: userData.firstName,
            lastname: userData.lastName,
            email: userData.email,
            password: userData.password,
            status: "I",
            metadata: {
                occupation: userData.occupation,
                age: userData.age,
                company: userData.company,
                industry: userData.industry,
                nationality: userData.nationality,
            },
            options: {
              notifyLearner: false,
            }
          }
      )
        .then((response) => {
          updateLoaderTo(false);
          let user = response.data.response;
          commit(SIGNUP_SUCCESS, user);

          resolve(response);
        },(error) => {
          updateLoaderTo(false);
          commit(SIGNUP_FAILURE);
          reject(error);
        })
        .catch((error) => {
            updateLoaderTo(false);
        });
    });
  },
};

const mutations = {
    [SIGNUP_SUCCESS](state, user) {
        state.user = user;
    },
    [SIGNUP_FAILURE](state) {
        state.user = null;
    },
    [CLEAR_MESSAGE](state) {
        state.message = null;
    },
    [SET_MESSAGE](state, message) {
      state.message = message;
    },
    [SET_USER](state, user) {
      state.user = user;
    },
};

const getters = {
  fullNameCapitalizeFirstLetter: (state) => {
    if (!state.user) return '';
    return (state.user.firstname + " " + state.user.lastname).replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
  },
};

export let updateLoaderTo = (loading) => {
    store.dispatch(
        `loader/${TOGGLE_LOADING}`,
        { loading: loading },
        { root: true }
    );
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
