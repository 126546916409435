import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from "./store/index";
import Vuex from 'vuex';

Vue.config.productionTip = false

new Vue({
  Vuex,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
