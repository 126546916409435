import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/views/HomePage.vue'
import RegisterForm from "@/views/RegisterForm";
import RegisterSuccess from "@/views/RegisterSuccess";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            component: HomePage,
            meta: { title: 'Home | Gigalogy Academy' }
        },
        {
            path: '/register',
            component: RegisterForm,
            meta: { title: 'Register | Gigalogy Academy' }},
        {
            path: '/register-success',
            component: RegisterSuccess,
            meta: { title: 'Register - Success | Gigalogy Academy' }
        },
    ]
})

function addDynamicPageTitle(to, from, next) {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
}

router.beforeEach((to, from, next) => {
    // Function for adding dynamic page title
    addDynamicPageTitle(to, from, next);
    next();
});

export default router;
