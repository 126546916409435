<template>
  <v-container class="fill-height pa-0">
    <v-row class="fill-height ma-0" justify="space-around">
      <v-col xs="4" sm="4" md="6" lg="6" class="pa-0">
        <v-img
          :src="require('@/assets/space_anim.webp')"
          :lazy-src="require('@/assets/space_blur.webp')"
          :width="`${ bgWidth }`"
        >
          <v-sheet
            color="transparent"
            class="overflow-y-auto align-self-center"
            :max-height="`${ bgHeight }`"
          >
            <div
              class="d-flex flex-column justify-space-around"
              style="margin-top: 250px;"
            >
              <v-card
                class="mx-auto justify-start card-shadow"
                :width="`${cardWidth}%`"
                outlined
                tile
              >
                <v-avatar size="100" class="robot">
                  <v-img
                    aspect-ratio="1"
                    alt="user"
                    :src="require('@/assets/robot.webp')"/>
                </v-avatar>
                <v-card
                  class="mx-auto d-flex flex-column justify-start align-content-space-around"
                  flat
                  min-height="230px"
                >
                  <v-card-title class="mx-auto pt-12 all_done all_done_in">ALL DONE!</v-card-title>
                  <v-card-subtitle class="mx-auto d-flex flex-column subtitle-1 mt-0 pb-0 px-1 text-center black--text  message message_in">
                    <p class="px-2 px-lg-5 px-xl-10 text-center">
                      Thanks for registering with us.
                      We will review your profile and
                      get back to you shortly.
                    </p>
                  </v-card-subtitle>
                </v-card>
              </v-card>
            </div>
          </v-sheet>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

  export default {
    name: 'RegisterSuccess',
    data: () => ({
    }),
    mounted() {
      if(!this.user){
        this.$router.push('/register');
      }
    },

    computed: {
      ...mapState({
        user: (state) => state.account.user,
        account: (state) => state.account.account,
      }),
      bgHeight() {
        return this.$vuetify.breakpoint.height
      },
      bgWidth() {
        return this.$vuetify.breakpoint.width
      },
      cardWidth () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': switch (this.$vuetify.breakpoint.width <= 320){
            case true: return 94
            default: return 84
          }
          case 'sm': return 84
          case 'md': return 90
          case 'lg': return 75
          case 'xl': return 60
          default: return 70
        }
      },
    },
    methods: {
    },
  }
</script>

<style scoped>
.robot{
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
  top: -25%;
}

.card-shadow{
  box-shadow: 4px 4px #000033;
}

.all_done{
  color: #286577;
  font-weight: 800;
  font-size: 22px;
}

.all_done_in{
  transform: translateX(0%);
  animation: all-done-in-anim .5s ease-out forwards;
  opacity: 0;
}

.message{
  font-weight: 500;
  line-height: normal;
}

.message_in{
  opacity: 0;
  transform: translateX(-25%);
  animation: message-in-anim .7s ease-out forwards;
}
.v-input--is-focused .v-input__slot {
  border: 2px solid black !important;
  border-bottom-color: black !important;
}

@keyframes message-in-anim {
  20% {
    opacity: 0;
    transform: translateX(-20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes all-done-in-anim {
  20% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}


.v-menu__content{
  width: 45% !important;
}

.v-input__slot input {
  direction: rtl;
}

.v-btn--outlined {
  border: 3px solid black;
  background-color: #F9F7FE;
}

.input--dense{
  height: 60px !important;
}

</style>
