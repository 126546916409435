/* eslint-disable */
import axios from "axios";
import store from "@/store";

import { CLEAR_MESSAGE, SET_MESSAGE } from "@/store/_mutationtypes";

function makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 32; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}

let api = axios.create({
    baseURL: process.env['VUE_APP_TOM_API_HOST'] + '/api/v3/'  // a slash(/) is necessary at the end of baseurl for later fullurl generation
});

api.interceptors.request.use(
    (request) => {
        request.headers["Content-Type"] = 'application/json';
        let ts = new Date();
        ts = ("" + ts).substring(0,10);
        const app = process.env['VUE_APP_TOM_APP_ID'];
        const nonce = makeid();
        const api_key = process.env['VUE_APP_TOM_API_KEY'];
        const api_secret = process.env['VUE_APP_TOM_API_SECRET'];
        let full_url = request.baseURL + request.url;
        var requestUrl = encodeURI(full_url.replace(/{{(\w*)}}/g,function(str,key) {
            return process.env['VUE_APP_TOM_API_HOST'];
        }));

        if (request.method == "GET" || request.method == "DELETE"
            ||(typeof request.data == "object" && JSON.stringify(request.data).length <= 2)
            ||(typeof request.data == "array" && JSON.stringify(request.data).length <= 2)
            || (!request.headers['Content-Type'])) {
            var bodyHash = "";
        }
        else {
            var bodyHash = JSON.stringify(request.data);
        }

        let salt = api_secret
            + ts
            + app
            + CryptoJS.MD5(bodyHash + requestUrl)// payload + url
            + nonce;

        let hash = CryptoJS.SHA256(salt).toString();
        request.headers["X-TOM-API-KEY"] = api_key;
        request.headers["X-TOM-API-HASH"] = hash;
        request.headers["X-TOM-APP"] = process.env['VUE_APP_TOM_APP_ID'];
        request.headers["X-TOM-RTS"] = ts;
        request.headers["X-TOM-NONCE"] = nonce;
        request.headers["X-TOM-APPLICATION-LANGUAGE"] = process.env['VUE_APP_TOM_LEARNER_LANGUAGE'] || "en";
        return request;
    }
);

api.interceptors.response.use(
    (response) => {
      store.commit(`account/${CLEAR_MESSAGE}`);
      return response;
    },
    (error) => {
      let errormessage;
      if (error.response && error.response.status === 409 && error.response.data.meta.msg === 'LEARNER_PARAM_LOGIN_ALREADY_EXISTS') {
          errormessage = "An account using this email\n address already exists.";
      } else {
          errormessage = "Oops! Something went wrong!";
      }
      store.commit(`account/${SET_MESSAGE}`, errormessage);
      return Promise.reject(error);
    }
);

export default api;
